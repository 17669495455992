<template>
  <key-number-input
    :value="keyLength"
    :isValid="isKeyLengthValid"
    label="Délka klíče"
    invalidFeedback="Délka klíče musí být v rozmezí 2-30"
    @valueChanged="keyLengthChanged"
  />

  <cipher-text-area
    :value="cipherText"
    :isValid="isCipherTextValid"
    invalidFeedback="Zadej šifrový text pro prolomení"
    @valueChanged="cipherTextChanged"
  />

  <button @click="solve" class="danger-btn">Prolomit</button>
  <button @click="toggleSettings" class="special-btn">Nastavení</button>

  <div v-show="settingsShown">
    <solve-settings
      :value="numOfIterations"
      @valueChanged="numOfIterationsChanged"
      :max="100"  
    />
  </div>

  <div v-show="isSolving" class="solve-alert">Probíhá prolamování...</div>

  <solve-results :results="results" />
</template>

<script>
import KeyNumberInput from '@/components/input/KeyNumberInput'
import CipherTextArea from '@/components/input/CipherTextArea'
import SolveSettings from '@/components/solve/SolveSettings'
import SolveResults from '@/components/solve/SolveResults'

import { solveColumnar } from '@/solve/columnar.worker.js'

export default {
  components: {
    'key-number-input': KeyNumberInput,
    'cipher-text-area': CipherTextArea,
    'solve-settings': SolveSettings,
    'solve-results': SolveResults
  },
  data() {
    return {
      keyLength: 10,
      cipherText: '',
      numOfIterations: 10,
      results: [],
      settingsShown: false,
      isSolving: false
    }
  },
  methods: {
    keyLengthChanged(value) {
      this.keyLength = value
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.results = []
    },
    solve() {
      if (this.isSolving || this.numOfIterations == 0) return

      if (this.isKeyLengthValid && this.isCipherTextValid) {
        this.isSolving = true
        solveColumnar(this.cipherText, this.keyLength, this.numOfIterations)
          .then(result => {
            this.results.push(result)
            this.isSolving = false
          })
      }
    },
    toggleSettings() {
      this.settingsShown = !this.settingsShown
    },
    numOfIterationsChanged(value) {
      this.numOfIterations = value
    }
  },
  computed: {
    isKeyLengthValid() {
      return this.keyLength >= 2 && this.keyLength <= 30
    },
    isCipherTextValid() {
      return this.cipherText.length > 0
    }
  }
}
</script>