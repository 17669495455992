<template>
  <p>K prolomení je použit <a href="https://en.wikipedia.org/wiki/Hill_climbing">horolezecký algoritmus</a>, který je účinnější při opakovaném běhu. Více iterací znamená větší šanci prolomení, ale také větší časovou náročnost.</p>

  <label for="iterationInput">Počet iterací (1 - {{ max }}): </label>
  <input
    type="number"
    id="iterationInput"
    :value="value"
    @keyup="valueChanged"
    min="0"
    :max="max"
  />
</template>

<script>
export default {
  props: {
    value: Number,
    max: Number
  },
  emits: ['valueChanged'],
  methods: {
    valueChanged(event) {
      let value = parseInt(event.target.value)

      if (isNaN(value) || value < 0) value = 0
      else if (value > this.max) value = this.max

      event.target.value = value

      this.$emit('valueChanged', value)
    }
  }
}
</script>

<style scoped>
p {
  font-size: .85em;
  color: #666;
}

label {
  font-size: .8em;
  margin-right: .6em;
}

input {
  width: 100px;
  font-size: .8em;
  padding: .3em .5em;
}
</style>